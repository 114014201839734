<template>
    <div>
        <b-form-group>
            <b-form-textarea v-model="dataHtml" rows="15"/>
        </b-form-group>
        <div class="d-flex justify-content-lg-center">
            <b-button variant="primary" class="mr-1"
                @click="zipData(dataHtml)">
                Zip
            </b-button>
            <b-button variant="primary" 
                @click="unzipData(dataEnCode)">
                unZip
            </b-button>
        </div>
        <b-form-group class="mt-1">
            <b-form-textarea v-model="dataEnCode" rows="15" />
        </b-form-group>
        
    </div>
</template>
<script>
const {gzip, ungzip} = require('node-gzip');
export default {
    data() {
        return {
            dataHtml: null,
            dataEnCode: null
        }
    },
    methods: {
        zipData(x) {
            if(x) {
                gzip(x).then((res) => {
                    this.dataEnCode = res.toString('base64')
                })
            } else {
                this.showToast('error', 'Vui lòng nhập đầy đủ thông tin')
            }
            
        },
        unzipData(x) {
            if(x) {
                const gzipedData = Buffer.from(x, "base64");
                ungzip(gzipedData)
                    .then((decompressed) => {
                        this.dataHtml = decompressed.toString()
                    });
            } else {
                this.showToast('error', 'Vui lòng nhập đầy đủ thông tin')
            }
            
        }
    }
}
</script>
<style lang="">
    
</style>